import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import DocumentsInvoices from "./DocumentsInvoices.vue";
import { IDocumentsInvoices } from "@/models/Documents";

const defaultMocks = {
  $route: { params: { propertyUuid: "2" }, query: { year: "2024" } },
};

const defaultProps = {
  propertyUuid: "2",
  year: "2024",
};

const mockInvoicesDocuments: IDocumentsInvoices = {
  items: [
    {
      files: [
        {
          uuid: "1",
          downloadUrl: "https://example.com/invoice1.pdf",
          viewUrl: "https://example.com/invoice1.pdf",
          url: "https://example.com/invoice1.pdf",
          expiresAt: "2024-12-31",
        },
      ],
      date: "2024-01-15",
      type: 36,
    },
  ],
  incomeAndExpenseSummaries: [],
};

const component = (props = defaultProps, mocks = defaultMocks) => {
  return myRentalsShallowMount(DocumentsInvoices, {
    props,
    mocks,
    router: true,
  });
};

describe("DocumentsInvoices", () => {
  testMyRentalsShallowMount(DocumentsInvoices, {
    mocks: defaultMocks,
    router: true,
  });

  describe("When there are documents", () => {
    it("should set hasInvoicesDocuments to true", () => {
      // given
      const wrapper = component();
      wrapper.setData({ invoicesDocuments: mockInvoicesDocuments });
      // when
      // then
      expect(wrapper.vm.hasInvoicesDocuments).toBe(true);
    });
  });

  describe("When there are no documents", () => {
    it("should set hasInvoicesDocuments to false", () => {
      // given
      const wrapper = component();
      wrapper.setData({
        invoicesDocuments: {
          items: [],
          incomeAndExpenseSummaries: [],
        },
      });
      // when
      // then
      expect(wrapper.vm.hasInvoicesDocuments).toBe(false);
    });
  });
});
